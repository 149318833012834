/*
	Scroll styles
*/

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

*::-webkit-scrollbar {
	cursor: pointer !important;
	width: 8px;
    height:8px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
	background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	background-color: transparent;
}

#root::-webkit-scrollbar {
    background-color: #fff;
}
