@import 'normalize.css';
@import './scroll.css';

/*
	ALL
*/

* {
    outline: none !important;
}

/*
	HTML, BODY
*/

html {
    font-size: 7.5px;
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: rgb(77, 102, 128);
    /*background-image: url("/assets/images/logo.svg");*/
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: calc(50% - 50px);
    background-size: 100px;
}

body {
    font-size: 7.5px;
    line-height: 1.4;
}

html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

/*#root {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    overflow: hidden;*/
/*    !*overflow-y: hidden;*!*/
/*    !*overflow-x: auto;*!*/
/*    !*position: relative;*!*/
/*}*/

.gtb-page-scroll {

}

/*
	Default font sizes.
*/
.zoom_large {
    font-size: 10px;
}

.zoom_small {
    font-size: 7.5px;
}

/*
	Typography
*/

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

a[role=button] {
    text-decoration: none;
}


